<template>
    <div class="ywjs">
        <ContactUs></ContactUs>
        <div class="ywjs_m">
            <div class="part_header"><router-link to="/">首页</router-link> > 业务介绍</div>
            <div class="ywjs_content">
                <h3>互联网彩票运营</h3>
                <p>互联网彩票缘起于2000年电脑彩票，至2015年间以互联网代购商业模式成为了互联网彩票行业的主要商业模式。在此期间瞻世趣创业团队设计了包括集中出票模式、店网结合模式、电子支付应用、数字签名（CA）应用、电商平台合作、体育彩票电子投注单接口等多项业务形成了行业标准在行业中广泛应用。</p>
                <p>2014年互联网代购业务年销售额突破800亿元人民币（官方统计650亿元），随着业务发展，逐渐暴露出了以彩票投注站（销售终端设备）为核心的彩票代购存在的管理问题，因此2015年2月底财政部、民政部、体育总局等部委对互联网彩票代购业务发出了禁止开展未经授权的互联网彩票销售业务，对互联网彩票代销相关行业产生了巨大影响，成为了中国彩票发展的历史性事件。我们认为互联网代购业务的中止是中国彩票行业发展的必经过程，对于行业发展来说并不是毁灭性的打击，而是将互联网彩票销售服务工作规范化的一个过程。面对未来的互联网彩票销售和推广服务，主客体的关系已经发生了根本性的变化，彩票管理机构和从业企业需要在新的规范和市场环境下发展，能够及时、准确的找到符合政策环境和未来市场发展的切入点正是瞻世趣的优势。</p>
                <div class="line"></div>
                <h3>彩票业务优化</h3>
                <p>纵观彩票行业，会发现从事彩票运营的企业为数众多，其业务模式大体分为投注工具服务（走势图、赛事数据等）、营销服务、机构服务、自助销售终端服务、线上对线下服务（O2O）、投注推荐服务、资讯信息服务等，这些业务是建立在彩票产业链上的碎片或节点，集中在某一个碎片或节点上开展业务，很容易出现服务对象偏差或业务衔接断层。瞻世趣能够通过自身对彩票产业链的深入了解和大量的合作关系，将业务碎片进行桥接，大幅提升合作伙伴的运营效率，形成共赢。</p>
                <div class="line"></div>
                <h3>游戏玩法设计与运营</h3>
                <p>彩票销售的核心产品是游戏玩法，当前我国发行的彩票玩法包含纸质即开彩票、电子即开彩票（原生与纸质即开彩票的电子化即开彩票和完全独立的游戏型彩票）、竞猜彩票（体育竞猜、事件竞猜）、乐透型彩票（大中小盘游戏）、高频彩票（泛指开奖频率较高的游戏产品，其中包含数字型和虚拟竞猜型）、视频大厅彩票。每一类游戏产品所面对的玩家群体和销售形式都截然不同，在各行各业精细化运营的今天，无论申请新游戏玩法还是对原有游戏产品改版都需要对游戏产品本身拥有充分的认识。</p>
                <p>瞻世趣的核心团队通过十余年深入游戏玩法设计（引进）、申报、试销、推广等一线的工作过程中积累了大量的经验，能够制定完整的彩票游戏产品解决方案。</p>
                <div class="line"></div>
                <h3>咨询顾问业务</h3>
                <p>借助瞻世趣的行业经验与人脉网络，我们长期面向有意于投资彩票相关行业的金融机构提供重要的辅助决策信息，并协助投资机构进行市场预判，开展准确的背景调查和价值分析工作。我们能够迅速准确的判断出每个行业企业的优劣势，能够协助行业企业寻求资源、资金合作。除市场工作外，瞻世趣也作为彩票发行、销售、服务机构信息来源，参与多项市场调研工作，为彩票相关政策制定提供了大量依据。</p>
            </div>
        </div>
    </div>
</template>
<script>
    import ContactUs from "@/components/common/contactus.vue";
    export default {
        name: 'Gywm',
        components: {
            ContactUs
        }
    }
</script>
<style lang="less" scoped>
    .ywjs{
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
        .ywjs_m{
            width: 700px;
            margin-bottom: 100px;
            .ywjs_content{
                color: rgb(127, 127, 127);
                font-size: 16px;
                p {
                    line-height: 1.75em;
                    text-indent: 2em;
                }
                h3 {
                    color: rgb(44, 90, 168);
                    margin-top: 15px;
                }
                .line{
                    border-bottom: 1px solid #ccc;
                    margin: 25px 0;
                }
            }
        }
    }
    
</style>